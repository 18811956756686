import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import {
  createTheme, CssBaseline, ThemeOptions, ThemeProvider
} from '@mui/material';

export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({ element }, { theme }) => (
  <ThemeProvider theme={createTheme(theme as ThemeOptions)}>
    <CssBaseline />
    {element}
  </ThemeProvider>
);